/* You can add global styles to this file, and also import other style files */
@import 'libs/ease/ease-import';
@import 'libs/ease/ease-styles';
@import '@angular/cdk/overlay-prebuilt.css';

@font-face {
  font-family: 'aw-icon-font';
  src:
    url('/assets/fonts/aw-icon-font.ttf?g79okgaf') format('truetype'),
    url('/assets/fonts/aw-icon-font.woff?g79okgaf') format('woff'),
    url('/assets/fonts/aw-icon-font.svg?g79okgaf#q-icon-font') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@-moz-document url-prefix() {
  body {
    webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

html {
  // max-height: 100vh;
  height: 100%;
  overflow: hidden !important;
}

// Cdk overlay's override for PopupComponent
.popup-overlay-container {
  &.cdk-overlay-container {
    // make backdrop transparent, as it is only needed to avoid click event propagation
    .popup-backdrop {
      opacity: 0;
    }
  }
}

body {
  height: 100%;
  // position: relative; // causing problems in safari; checking if everything still works fine
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  @include media-breakpoint-up(s) {
    min-height: auto !important;
  }

  @media screen and (max-height: 940px) {
    scrollbar-width: none;
  }

  &.rdy,
  &.outdated {
    background: var(--color-white);
    background-color: var(--color-white);
  }
}

dl,
dd,
dt {
  margin: 0;
}

.drop-down {
  width: 460px;
}

.overflow-hidden {
  overflow: hidden;
}

.aw-2-img--big-visual {
  max-width: 80%;
  max-height: 300px;
  height: auto;
}

.hoverable:hover {
  color: var(--color-text-blue) !important;
}

.white-box {
  border-radius: 18px;
  @include shadow(m);

  box-sizing: border-box;
  background: var(--color-white);
  padding: 0 !important;

  &.item {
    max-width: 300px;
    height: 220px;
    cursor: pointer;
  }

  &.button {
    border: 2px dashed $color-steel;
    background-color: transparent;

    div {
      width: 100%;
    }
  }

  .white-box-header,
  .white-box-body {
    padding: 10px 20px;
  }

  .white-box-header {
    border-bottom: 1px solid var(--color-fog);

    h5 {
      margin-bottom: 0;
    }
  }

  .title {
    border-bottom: 1px solid var(--color-fog);
    text-align: center;
    font-weight: $font-weight-bold;
    padding: 15px;
    text-transform: uppercase;
    color: $color-steel;
  }

  .contact-type {
    border-bottom: 1px solid var(--color-fog);
    padding: 5px;

    &:last-of-type,
    &:last-child > div {
      border-bottom: none;
    }

    i {
      float: left;
      margin: 20px;
      color: $color-steel;
    }

    .contact-item {
      padding: 10px 10px 0 10px;
      margin-left: 60px;
      color: var(--color-night);
      overflow: hidden;
      text-overflow: ellipsis;

      ::ng-deep {
        a,
        a:active,
        a:link,
        a:visited,
        a:hover {
          color: var(--color-night);
          text-decoration: none;
          font-weight: $font-weight-normal;
        }
      }

      .link {
        text-overflow: ellipsis;
        width: 100%;
        white-space: nowrap;
        display: inline-block;
        overflow: hidden;
      }

      &:last-child {
        padding-bottom: 10px;
      }

      .type {
        font-size: 12px;
        line-height: 12px;
        color: $color-steel;
        text-transform: lowercase;
      }
    }
  }
}

/*
Layout Elements
*/

// used for the d&d preview
.dnd-drag-preview {
  position: absolute;
  z-index: 9999;
  left: -9999px;
  margin: 0;
  padding: 0;
  box-shadow:
    0 19px 38px rgba(0, 0, 0, 0.3),
    0 15px 12px rgba(0, 0, 0, 0.22);
  pointer-events: none; // important to enable the dragging events
  transition: none !important; // important to avoid any lag in moving
}

.drag-handle {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;

  /*Apply a "closed-hand" cursor during drag operation. */
  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
}

.list-selector-wrapper {
  @extend .aw-2-pos--relative;

  i {
    @extend .aw-2-icn;
    @extend .aw-2-txt--white;
    @extend .aw-2-pos--center;
  }

  left: -55px !important;
  width: 0;

  .checkbox {
    display: block;
    opacity: 0;
    z-index: 1;
    width: 18px;
    height: 18px;
    border-radius: 25%;
    background: var(--color-ice);
    cursor: pointer;
    text-align: center;
    border: 2px solid $color-fog;
    transition: background 100ms ease-in;

    i {
      line-height: 18px;
      user-select: none;
      display: none;
    }

    &.active {
      border-color: $color-steel;
    }

    &:hover {
      border-color: $color-steel;
    }

    &.done {
      background-color: $color-blue;
      border-color: $color-blue;
      i {
        display: block;
      }
    }
  }
}

body {
  line-height: 1.33;

  background: linear-gradient(135deg, #dce2ff 0%, #e4d5ff 33%, #fadcff 66%, #fddfeb 100%);
  background-color: #dce2ff; // fallback
}

// Move to ease

:root {
  --color-contrasty-border: #c0d9ee;

  body.aw-2-dark {
    --color-contrasty-border: #56566f;
  }
}

// jumpy animation - used for ongoing timetrackings
@keyframes jump {
  0% {
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  }
  40% {
    transform: translate3d(0, -8%, 0) scale3d(0.9, 1.1, 1);
  }
  100% {
    transform: translate3d(0, -20%, 0) scale3d(1.1, 0.9, 1);
  }
}
.jump {
  transform-origin: 50% 50%;
  animation: jump 0.5s linear alternate infinite;
}

.detail-wrapper {
  position: relative;
  display: flex;
  overflow: auto;

  .content-wrapper {
    // overflow-x: hidden;
    max-width: 100%;
    min-width: 320px;
  }
}

// TODO: Remove once completely migrated
.detail-content-wrapper {
  .detail-content {
    &.clear {
      background: transparent;
      box-shadow: none;
      padding: 0;
    }

    .content-block {
      border-bottom: 1px solid $color-fog;
      padding: 15px 0;

      &.flex {
        display: flex;
        flex-direction: row;
      }

      &:first-of-type {
        border-top: 1px solid $color-fog;
      }
      &:last-of-type {
        border-bottom: none;
      }

      .content-group-title {
        width: 150px;
      }
      .content-group-items {
        flex: 1;

        .content-item {
          display: flex;
          flex-direction: row;
          padding-bottom: 10px;

          &:last-of-type {
            padding-bottom: 0;
          }

          .content-label {
            width: 150px;
            font-weight: $font-weight-bold;

            @include media-breakpoint-down(s) {
              display: none;
            }
          }

          .content {
            flex: 1;
          }
        }
      }
    }
  }
}

.summary-wrapper {
  align-items: center;
  justify-content: center;
}

.summary-item {
  text-align: center;
  display: inline-block;
  min-width: 100px;
  vertical-align: top;

  .summary-title {
    @extend .aw-2-txt--body-small;
    color: $color-steel;
    padding: 5px 0 10px 0;
  }

  .summary-content {
    white-space: nowrap;

    .aw-skl--title {
      height: 16px;

      &:empty {
        width: 65%;
        display: inline-block;
      }
    }
  }
}

.file-tag {
  display: inline-flex;
  align-items: center;
  flex-flow: row-reverse;
  margin-left: 2px;
  user-select: none;
  background: $color-ice;
  color: $color-night;
  padding: 1px 3px;
  border-radius: 6px;
  font-size: 12px;
  vertical-align: inherit;

  &--preview {
    background: none;
    box-shadow: unset;
    display: inline-block;
    margin: 0;
    padding: 0;
  }
}

:host-context(.aw-2-dark) {
  .file-tag {
    background: $color-fog;
  }
}

.emoji--xl {
  font-size: 6rem;
  line-height: 6rem;
}

// Multiline trimming

.multi-line-trimming {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

// Text divider

.divider {
  position: relative;
  z-index: 1;
  overflow: hidden;
  text-align: center;

  &::before {
    margin-left: -50%;
    text-align: right;
  }

  &::before,
  &::after {
    position: absolute;
    top: 50%;
    overflow: hidden;
    width: 50%;
    height: 1px;
    content: '\a0';
    background-color: $color-fog;
  }

  span {
    display: inline-block;
    padding: 0 10px;
  }
}

.multi-line-trimming {
  height: 2.8em;
}

// -----------------------------Scrollbar override-------------------------------

// prevent the override for mobile devices
body.aw-web {
  /* total width */
  ::-webkit-scrollbar {
    background-color: transparent;
    width: 16px;
    height: 16px;
  }
  /* background of the scrollbar except button or resizer */
  ::-webkit-scrollbar-track {
    background-color: rgba(var(--color-white-rgb), 0.2);
  }
  ::-webkit-scrollbar-track:hover {
    background-color: rgba(var(--color-white-rgb), 0.2);
  }
  /* scrollbar itself */
  ::-webkit-scrollbar-thumb {
    background-color: $color-fog;
    border-radius: 16px;
    border: 5px solid transparent;
    background-clip: padding-box;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: $color-steel;
    border: 4px solid transparent;
  }
  /* set button(top and bottom of the scrollbar) */
  ::-webkit-scrollbar-button {
    display: none;
  }
  // set the corner square
  ::-webkit-scrollbar-corner {
    background: rgba(var(--color-white-rgb), 0.2);
  }
}

.cdk-drag {
  &:hover {
    cursor: grab;
  }

  &:active {
    cursor: grabbing;
  }
}

.cdk-drag-preview {
  overflow: visible;
}

@media print {
  .hideOnPrint {
    display: none;
  }
  aw-app-print-layout {
    display: block;
  }
  html {
    overflow: visible !important;
  }
}

// Fix for firefox to prevent cutting prints after first page
@media print {
  .aw-lyt {
    display: block;
  }
}

// Shockwave animation

.shockwave {
  transform: scale(0);
  animation: main-loader 1s forwards;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    height: 2em;
    width: 2em;
    margin: -1em;
    border: 0 solid $color-purple;
    border-radius: 50%;
    opacity: 0;
    animation: outer-loader 1s 1;
  }

  @keyframes outer-loader {
    25% {
      opacity: 1;
    }
    45% {
      height: 2em;
      width: 2em;
      margin: -1em;
      opacity: 1;
      border-width: 0;
    }
    85% {
      height: 3em;
      width: 3em;
      margin: -3em;
      opacity: 0;
      border-width: 1.5em;
    }
  }

  @keyframes main-loader {
    5% {
      transform: scale(1);
    }
    10% {
      transform: scale(1.25);
    }
    25% {
      transform: scale(0.9);
    }
    35% {
      transform: scale(1);
    }
    45% {
      transform: scale(1.5);
    }
    80% {
      transform: scale(1);
    }
    100% {
      transform: scale(1);
    }
  }
}

.aw-tip {
  background-color: var(--color-white);
}

// Global settings for the medium-editor toolbar
.medium-editor-toolbar {
  background-color: $color-night;
  border-radius: 10px;
  padding: 8px 16px;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  z-index: 5000;
  transition:
    opacity 150ms ease-out,
    visibility 0s linear 150ms;

  &-active {
    visibility: visible;
    opacity: 1;
    transition:
      opacity 150ms ease-in,
      visibility 0s linear;
  }

  ul {
    display: flex !important;
    list-style: none;
    margin: 0;

    li {
      margin: 0;
      line-height: 0;

      button {
        appearance: none;
        background: none;
        border: none;
        padding: 0;

        i {
          color: $color-white;
          padding-left: 10px;

          &:hover {
            cursor: pointer;
            color: $color-blue;
          }
        }
      }

      .medium-editor-button {
        &-active {
          i {
            color: $color-blue;
          }
        }
      }

      &:first-child {
        button i {
          padding-left: 0;
        }
      }
    }
  }
}

.slash-command {
  position: relative;
  background-color: $color-ice;
  color: $color-steel;
  border-radius: 5px;
  display: inline-block;
  padding: 0 5px;
  &[contentEditable='true']:after {
    content: attr(data-text);
    color: $color-steel;
    font-style: italic;
    pointer-events: none;
  }
}

//---------------------- MONSTER MIGRATION ----------------------

.content-limit {
  margin: 0px auto;
  max-width: 1000px;

  @include media-breakpoint-up(m) {
    padding-left: $mobile-content-spacing;
    padding-right: $mobile-content-spacing;
  }
}

.widescreen {
  max-width: 1600px !important;
}

.clickable {
  cursor: pointer;
}

.blur {
  animation: blur 1.5s ease forwards;
  filter: blur(0px);
}

@keyframes blur {
  to {
    filter: blur(5px);
  }
}

.aw-2-w--100 {
  width: 100%;
}

.aw-2-w--max-100 {
  max-width: 100%;
}

.aw-2-max-w--fit-content {
  max-width: fit-content;
}

.mention {
  user-select: none;
  color: var(--color-text-blue);
}

.detail-content-wrapper {
  margin: $spacing-xxxl 10px $spacing-xl 10px;

  .detail-content {
    margin: 0 auto;
    max-width: 1000px;
    background: var(--color-white);
    box-sizing: border-box;
    padding: $spacing-xxl;
    position: relative;
    border-radius: $border-size-xxl;
    border: 6px solid var(--color-fat-border);
    box-shadow: unset;

    .content-block {
      border-bottom: 1px solid $color-sky;
      padding: $spacing-xxl 0;
    }

    .title-content {
      display: flex;
      flex-direction: row;
      margin-bottom: $spacing-m;
      gap: $spacing-xs;

      .title-image {
        margin: 0 $spacing-l 0 0;

        @include media-breakpoint-down(s) {
          display: none;
        }
      }

      .title-text {
        flex: 1;
        overflow: hidden;
        padding-top: 0;
      }

      .title-actions {
        position: relative;
      }
    }
  }
}

.placeholder-element {
  white-space: nowrap;
  display: inline-block;
  border-radius: 40px;
  user-select: none;
  background-color: $color-purple-o-50;
  color: var(--color-text-purple);
  font-size: 14px;
  padding-left: 6px;
  padding-right: 6px;
  line-height: 14px;
}

// ----------------------------- Flat Large List Item -------------------------------
// These global styles handles the divider behaviour for the flat large list item
// On hover it needs to remove the border bottom of the hovered item and the item above

aw-flat-large-list-item {
  .flat-large-list-item {
    &:not(.is-last) {
      .flat-item-wrapper {
        border-bottom: 1px solid $color-fog;
      }
    }
  }

  &:hover,
  &:has(+ aw-flat-large-list-item:hover) {
    .flat-large-list-item {
      .flat-item-wrapper {
        &.flat-item-divider {
          border-color: transparent;
        }
      }
    }
  }
}
// ----------------------------- Task Item Hover -------------------------------
// These global styles handles the behaviour when hovering a task item
// On hover it needs to remove the border bottom of the hovered item and the item above

.task-row {
  &:hover {
    .task-separator {
      opacity: 0;
    }
  }

  // this selector has 2 parts:
  // 1. hiding the bottom border of the previously hided element: "aw-task-item:has(+ aw-task-item:hover)"
  // 2. an exception: not hiding the border when the .new-task-button-wrapper is hovered: ":not(:has(+ aw-task-item .new-task-button-wrapper:hover))"
  &:has(+ .task-row:hover):not(:has(+ .task-row .new-task-button-wrapper:hover)) {
    .task-separator {
      opacity: 0;
    }
  }
}

.task-list-row {
  &:has(+ .task-row:hover):not(:has(+ .task-row .new-task-button-wrapper:hover)) {
    .task-add-separator {
      opacity: 0;
    }
  }
}

// ----------------------------- Task Item Selected -------------------------------
// These global styles handles the behaviour when a task item is selected
// When selected it needs to remove the border bottom of the hovered item and the item above

.task-row {
  &:has(+ .task-row.selected),
  &.selected {
    .task-separator {
      opacity: 0;
    }
  }
}

.task-list-row {
  &:has(+ .task-row.selected) {
    .task-add-separator {
      opacity: 0;
    }
  }
}

.task-row.last {
  .task-separator {
    opacity: 0;
  }
}

.add-box {
  border: 2px dashed $color-light-steel;
  border-radius: 18px$border-size-m;
  min-height: 40px;
  position: relative;
  cursor: pointer;
  transition: all 0.1.5s ease-in;
  box-sizing: border-box;

  &:hover {
    background: $color-blue-o-50;
    border-color: transparent;

    div,
    i {
      color: var(--color-text-blue);
    }
  }

  &:active {
    background: $color-blue-o-100;
    border-color: transparent;
    div,
    i {
      color: var(--color-text-blue);
    }
  }

  div,
  i {
    color: $color-steel;
    user-select: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

// List selection
tr {
  &.selected {
    td {
      background: $color-blue-o-50;

      &:first-of-type {
        border-top-left-radius: $border-size-l;
        border-bottom-left-radius: $border-size-l;
      }
      &:last-of-type {
        border-top-right-radius: $border-size-l;
        border-bottom-right-radius: $border-size-l;
      }

      &,
      i {
        color: var(--color-text-blue);
      }
    }
  }
}

.cdk-virtual-scroll-orientation-vertical .cdk-virtual-scroll-content-wrapper {
  max-width: 100%;
}

.onboarding-ui__box {
  padding: $spacing-xs;
  background: linear-gradient(135deg, #c4a1ff, #f0c9dd);
  border-radius: $border-size-xl;

  .onboarding-ui__inner-box {
    border-radius: $border-size-m;
    background: $color-white;
  }
}
