@import 'libs/ease/ease-import';
@import '../app/_shared/styles/rich-text-html';

$colors: (
  'green': (
    100: $color-green-100,
    900: $color-green-900
  ),
  'yellow': (
    100: $color-yellow-100,
    900: $color-yellow-900
  ),
  'blue': (
    100: $color-blue-100,
    900: $color-blue-900
  ),
  'purple': (
    100: $color-purple-100,
    900: $color-purple-900
  ),
  'red': (
    100: $color-red-100,
    900: $color-red-900
  ),
  'cyan': (
    100: $color-cyan-100,
    900: $color-cyan-900
  ),
  'indigo': (
    100: $color-indigo-100,
    900: $color-indigo-900
  ),
  'orange': (
    100: $color-orange-100,
    900: $color-orange-900
  )
);

.tiptap {
  @include rich-text-code-element;

  font-weight: 400;
  padding: 14px 8px 14px 16px;

  p {
    margin-bottom: $spacing-s;
    font-weight: 400;
  }

  h1,
  h2,
  h3 {
    margin: 0 0 1em 0;
    font-weight: $font-weight-bold;

    strong {
      font-weight: $font-weight-bold;
    }
  }

  p + :is(h1, h2, h3),
  ul + :is(h1, h2, h3),
  ol + :is(h1, h2, h3) {
    padding-top: $spacing-l;
  }

  h2 {
    font-size: 20px;
  }

  i {
    font-style: italic;
  }

  i.aw-2-icn {
    font-style: normal;
  }

  hr {
    margin: $spacing-l 0;
    border: 0;
    border-top: 1px solid $color-fog;

    &.ProseMirror-selectednode {
      border-top: 1px solid $color-blue;
    }
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: auto;
  }

  ol,
  ul {
    margin: $spacing-s;
    padding-left: $spacing-s;
    font-weight: 400;

    li {
      margin-bottom: $spacing-xs;

      & > p {
        margin-bottom: 0;
      }
    }

    ol,
    ul {
      margin: $spacing-xs 0 $spacing-xs $spacing-l;
    }
  }

  /* PLACEHOLDER ELEMENT */

  .is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    color: $color-steel;
    font-style: italic;
    float: left;
    height: 0;
    pointer-events: none;
  }

  // Editor has focus
  &.ProseMirror-focused {
    // Placeholder
    .is-editor-empty:first-child::before {
      content: '';
    }
  }

  .ProseMirror-separator {
    opacity: 0;
  }

  /* CUSTOM PREVIEW ELEMENTS */

  // File preview styles
  .file-tag {
    border: 1.4px solid $color-ice;
    background-color: $color-ice;
    border-radius: 4px;
    line-height: 18px;

    aw-file-preview-element {
      display: inline-block;

      .wrapper {
        margin: 0 10px;

        .actions-wrapper {
          .aw-btn {
            width: 100px;
            height: 100px;
            margin: 0;
            padding: 0;
          }
        }

        &.icon,
        &.externalFile {
          .actions-wrapper {
            .aw-btn {
              width: 100%;
              height: 70px;
            }
          }
        }
      }

      .external-icon {
        left: 10px !important;
        top: 20px !important;
      }
    }

    &.ProseMirror-selectednode {
      border: 1.4px solid $color-blue;
    }
  }

  .file-tag,
  .mention[data-id*='userId'] {
    vertical-align: bottom;
  }

  .mention[data-id*='userId'] {
    display: inline-block;
    border: 1.4px solid $color-sky;
    background: $color-sky;
    border-radius: 6px;

    &.ProseMirror-selectednode {
      border: 1.4px solid $color-blue;
    }
  }

  // Live editing cursors styles
  .collaboration-cursor__caret {
    border-left: 1px solid #0d0d0d;
    border-right: 1px solid #0d0d0d;
    margin-left: -1px;
    margin-right: -1px;
    pointer-events: none;
    position: relative;
    word-break: normal;

    @each $color, $shades in $colors {
      &--#{$color} {
        border-color: map-get($shades, 100);
      }
    }
  }

  .collaboration-cursor__label {
    border-radius: 2px 2px 2px 0;
    color: $color-slate;
    font-size: $font-size-tiny;
    font-style: normal;
    font-weight: 600;
    left: -1px;
    line-height: 12px;
    padding: 1px 1px 1px 2px;
    position: absolute;
    top: -10px;
    user-select: none;
    white-space: nowrap;
    z-index: 1;

    @each $color, $shades in $colors {
      &--#{$color} {
        background-color: map-get($shades, 100);
        color: map-get($shades, 900);
      }
    }
  }
}

/* DARK MODE */
.aw-2-dark {
  .tiptap {
    hr {
      border-top-color: $color-night;

      &.ProseMirror-selectednode {
        border-top: 1px solid $color-blue;
      }
    }
  }
}

/* COMMENT INPUT THEME */
.tiptap.comment-input {
  line-break: initial;

  h1 {
    font-size: 20px;
    line-height: 24px;
  }

  h2 {
    font-size: 16px;
    line-height: 24px;
  }

  h3 {
    font-size: 14px;
    line-height: 20px;
  }

  h1,
  h2,
  h3 {
    margin: 0 0 0.6em 0;
  }

  p + :is(h1, h2, h3),
  ul + :is(h1, h2, h3),
  ol + :is(h1, h2, h3) {
    padding-top: 12px;
  }

  :last-child {
    // Remove any extra margin given by </br> or <p> generated by the content editable
    margin-bottom: 0;
  }

  &.current-user {
    h1,
    h2,
    h3 {
      color: var(--dark-element-text);
    }

    .mention {
      color: var(--dark-element-text);
    }

    code {
      background-color: $color-ice;
    }
  }
}

/* NOTES WIDGET LIGHT THEME */

.tiptap.notes {
  color: $gray-light-500;
  cursor: text;

  h1,
  h2,
  h3 {
    color: $color-yellow-1000;
  }

  a {
    color: $color-blue-900;
  }

  code {
    color: $color-yellow-1000;
    background-color: $color-yellow-o-500;
  }

  hr {
    border-color: $color-yellow-500;
  }
}

/* NOTES WIDGET DARK THEME */

.aw-2-dark {
  .tiptap.notes {
    color: $color-yellow-900;

    h1,
    h2,
    h3 {
      color: $gray-light-0;
    }

    a {
      color: $color-blue-700;
    }

    code {
      color: $color-yellow-100;
      background-color: $color-yellow-o-50;
    }

    hr {
      border-color: $color-yellow-100;
    }
  }
}

.tiptap.document {
  color: var(--color-text-normal);
  padding: 0;
}
