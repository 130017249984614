@import 'libs/ease/ease-import';

$color-loader: $color-sky;

:host {
  font-size: 0; // needed for propper auto height
  line-height: 0;
  display: block;
}

@mixin loaderBar($unique-name, $height, $width) {
  .#{$unique-name}:empty {
    width: $width;
    height: $height;
    display: inline-block;
    background-image: linear-gradient(
        120deg,
        rgba(var(--color-white-rgb), 0),
        rgba(var(--color-white-rgb), 0.5) 50%,
        rgba(var(--color-white-rgb), 0) 80%
      ),
      radial-gradient(circle $height * 0.5 at $height * 0.5 $height * 0.5, $color-loader 99%, transparent 0),
      radial-gradient(circle $height * 0.5 at $height * 0.5 $height * 0.5, $color-loader 99%, transparent 0),
      linear-gradient($color-loader $height, transparent 0);
    background-repeat: no-repeat;
    background-size:
      75px $height,
      $height $height,
      $height $height,
      ($width - $height) $height;
    background-position:
      -75px 0,
      0 0,
      $width - $height 0,
      $height * 0.5 0;
    animation: #{$unique-name} 1.2s infinite;
  }
  @keyframes #{$unique-name} {
    to {
      background-position:
        $width + 75px 0,
        0 0,
        $width - $height 0,
        $height * 0.5 0;
    }
  }
}

@mixin loaderAvatar($unique-name, $height, $width, $spacer, $text-height) {
  .#{$unique-name}:empty {
    width: $width;
    height: $height;
    background-image: linear-gradient(
        120deg,
        rgba(var(--color-white-rgb), 0),
        rgba(var(--color-white-rgb), 0.5) 50%,
        rgba(var(--color-white-rgb), 0) 80%
      ),
      radial-gradient(circle $height * 0.5 at $height * 0.5 $height * 0.5, $color-loader 99%, transparent 0),
      radial-gradient(
        circle $text-height * 0.5 at $text-height * 0.5 $text-height * 0.5,
        $color-loader 99%,
        transparent 0
      ),
      radial-gradient(
        circle $text-height * 0.5 at $text-height * 0.5 $text-height * 0.5,
        $color-loader 99%,
        transparent 0
      ),
      linear-gradient($color-loader $text-height, transparent 0);
    background-repeat: no-repeat;
    background-size:
      75px $height,
      $height $height,
      $text-height $text-height,
      $text-height $text-height,
      ($width - $height - $text-height - $spacer) $text-height;
    background-position:
      -75px 0,
      0 0,
      $height + $spacer (($height - $text-height) * 0.5),
      $width - $text-height (($height - $text-height) * 0.5),
      $height + $spacer + $text-height * 0.5 (($height - $text-height) * 0.5);
    animation: #{$unique-name} 1s infinite;
  }
  @keyframes #{$unique-name} {
    to {
      background-position:
        $width + 75px 0,
        0 0,
        $height + $spacer (($height - $text-height) * 0.5),
        $width - $text-height (($height - $text-height) * 0.5),
        $height + $spacer + $text-height * 0.5 (($height - $text-height) * 0.5);
    }
  }
}

@mixin loaderCircle($unique-name, $size) {
  .#{$unique-name}:empty {
    width: $size;
    height: $size;
    background-image: linear-gradient(
        120deg,
        rgba(var(--color-white-rgb), 0),
        rgba(var(--color-white-rgb), 0.5) 50%,
        rgba(var(--color-white-rgb), 0) 80%
      ),
      radial-gradient(circle $size * 0.5 at $size * 0.5 $size * 0.5, $color-loader 99%, transparent 0);
    background-repeat: no-repeat;
    background-size:
      75px $size,
      $size $size;
    background-position:
      -75px 0,
      0 0;
    animation: #{$unique-name} 1s infinite;
  }
  @keyframes #{$unique-name} {
    to {
      background-position:
        $size + 75px 0,
        0 0;
    }
  }
}

// Text, size xl
@include loaderBar('loader--text--l--1', 40px, 220px);
@include loaderBar('loader--text--l--2', 40px, 200px);
@include loaderBar('loader--text--l--3', 40px, 180px);

// Text, size l
@include loaderBar('loader--text--l--1', 32px, 220px);
@include loaderBar('loader--text--l--2', 32px, 200px);
@include loaderBar('loader--text--l--3', 32px, 180px);

// Text, size m
@include loaderBar('loader--text--m--1', 24px, 200px);
@include loaderBar('loader--text--m--2', 24px, 180px);
@include loaderBar('loader--text--m--3', 24px, 160px);

// Text, size s
@include loaderBar('loader--text--s--1', 20px, 160px);
@include loaderBar('loader--text--s--2', 20px, 140px);
@include loaderBar('loader--text--s--3', 20px, 120px);

// Badge, size xl
@include loaderBar('loader--badge--l--1', 40px, 100px);
@include loaderBar('loader--badge--l--2', 40px, 90px);
@include loaderBar('loader--badge--l--3', 40px, 80px);

// Badge, size l
@include loaderBar('loader--badge--l--1', 36px, 100px);
@include loaderBar('loader--badge--l--2', 36px, 90px);
@include loaderBar('loader--badge--l--3', 36px, 80px);

// Badge, size m
@include loaderBar('loader--badge--m--1', 32px, 90px);
@include loaderBar('loader--badge--m--2', 32px, 80px);
@include loaderBar('loader--badge--m--3', 32px, 70px);

// Badge, size m
@include loaderBar('loader--badge--s--1', 24px, 80px);
@include loaderBar('loader--badge--s--2', 24px, 70px);
@include loaderBar('loader--badge--s--3', 24px, 60px);

// Avatar, size xl
@include loaderAvatar('loader--avatar--xl--1', 80px, 220px, 12px, 24px);
@include loaderAvatar('loader--avatar--xl--2', 80px, 200px, 12px, 24px);
@include loaderAvatar('loader--avatar--xl--3', 80px, 180px, 12px, 24px);

// Avatar, size l
@include loaderAvatar('loader--avatar--l--1', 40px, 220px, 12px, 24px);
@include loaderAvatar('loader--avatar--l--2', 40px, 200px, 12px, 24px);
@include loaderAvatar('loader--avatar--l--3', 40px, 180px, 12px, 24px);

// Avatar, size m
@include loaderAvatar('loader--avatar--m--1', 32px, 200px, 12px, 24px);
@include loaderAvatar('loader--avatar--m--2', 32px, 180px, 12px, 24px);
@include loaderAvatar('loader--avatar--m--3', 32px, 160px, 12px, 24px);

// Avatar, size s
@include loaderAvatar('loader--avatar--s--1', 24px, 180px, 8px, 20px);
@include loaderAvatar('loader--avatar--s--2', 24px, 160px, 8px, 20px);
@include loaderAvatar('loader--avatar--s--3', 24px, 140px, 8px, 20px);

// Circle
@include loaderCircle('loader--circle--s', 24px);
@include loaderCircle('loader--circle--m', 32px);
@include loaderCircle('loader--circle--l', 40px);
@include loaderCircle('loader--circle--xl', 80px);
