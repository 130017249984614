@use 'variables' as variables;
@use 'typography-mixins' as text;

body,
html {
  font-family: $font-family;
  font-size: $font-size-normal;
  font-weight: $font-weight-normal;
  line-height: $line-height-normal;
  color: var(--color-text-light);
  text-rendering: geometricPrecision;
  backface-visibility: hidden;
}

/* TEXT */
:root {
  @each $step, $value in variables.$font-size {
    --font-size-#{$step}: #{$value};
  }
}

:root {
  @each $step, $value in variables.$line-height {
    --line-height-#{$step}: #{$value};
  }
}

:root {
  @each $step, $value in variables.$font-weight {
    --font-weight-#{$step}: #{$value};
  }
}

.aw-2-txt--l,
.aw-2-txt--body-large {
  @include text.font-size-l();
}

p,
.aw-2-txt--m,
.aw-2-txt--body-regular {
  @include text.font-size-m();
}

.aw-2-txt--s,
.aw-2-txt--body-small {
  @include text.font-size-s();
}

.aw-2-txt--xs,
.aw-2-txt--body-tiny {
  @include text.font-size-xs();
}

/* TITLEs */
h1,
h2,
h3,
.aw-2-txt--h1,
.aw-2-txt--h2,
.aw-2-txt--h3 {
  @include text.heading();
}

h1,
.aw-2-txt--h1 {
  @include text.heading-h1();
}

h2,
.aw-2-txt--h2 {
  @include text.heading-h2();
}

h3,
.aw-2-txt--h3 {
  @include text.heading-h3();
}

h4,
.aw-2-txt--h4 {
  @include text.heading-h4();
}

/* STYLING */
strong,
b,
.aw-2-txt--bold {
  font-weight: variables.$font-weight-semi;
}

.aw-2-txt--bolder {
  font-weight: variables.$font-weight-bold;
}

em,
.aw-2-txt--italic {
  font-style: italic;
}

.aw-2-txt--no-wrap {
  white-space: nowrap;
}

.aw-2-txt--break {
  word-break: break-word;
}

.aw-2-txt--break-all {
  word-break: break-all;
}

.aw-2-txt--truncate {
  @include text.txt-truncate();
}

/* ALIGNMENT */
.aw-2-txt--left {
  text-align: left;
}

.aw-2-txt--right {
  text-align: right;
}

.aw-2-txt--center {
  text-align: center;
}

.aw-2-txt--text-top {
  vertical-align: text-top;
}

.aw-2-txt-middle {
  vertical-align: middle;
}

.aw-2-txt--line-height-0 {
  line-height: 0;
}

/* COLORS */
@each $color, $value in variables.$gray-colors {
  .aw-2-txt--#{$color} {
    color: $value !important;
  }
}

@each $color, $value in variables.$text-colors {
  .aw-2-txt--#{$color} {
    color: $value !important;
  }
}

/* EMPTY MESSAGES */
.aw-2-txt--empty {
  @include text.txt-empty();
}

/* LINKS */

a,
a:visited,
a:link .aw-2-txt--link {
  @include text.txt-link();
}

.aw-2-txt--link-purple {
  @include text.txt-link-purple();
}
