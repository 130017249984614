/* Positioning */
.aw-2-pos--center {
  margin-right: auto !important;
  margin-left: auto !important;
}

.aw-2-pos--vcenter {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.aw-2-pos--center-all {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.aw-2-pos--vmiddle {
  vertical-align: middle !important;
}

.aw-2-pos--vtop {
  vertical-align: top !important;
}

.aw-2-pos--bottom {
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.aw-2-pos--topright {
  position: absolute;
  top: 0px;
  right: 0px;
}

.aw-2-pos--relative {
  position: relative;
}

.aw-2-pos--absolute {
  position: absolute;
}

.aw-2-pos--fixed {
  position: fixed;
}

/* Float */
.aw-2-pos--float-right {
  float: right;
}
.aw-2-pos--float-left {
  float: left;
}
.aw-2-pos--float-clear {
  clear: both;
}

/* Flexbox */
.aw-2-flx {
  display: flex;
}

.aw-2-flx-inline {
  display: inline-flex;
}

.aw-2-flx--row {
  flex-direction: row;
}

.aw-2-flx--column {
  flex-direction: column;
}

.aw-2-flx--center {
  align-items: center;
}

.aw-2-flx--end {
  align-items: flex-end;
}

.aw-2-flx--start {
  align-items: flex-start;
}

.aw-2-flx--baseline {
  align-items: baseline;
}

.aw-2-flx--justify-center {
  justify-content: center;
}

.aw-2-flx--justify-end {
  justify-content: end;
}

.aw-2-flx--justify-space-between {
  justify-content: space-between;
}

.aw-2-flx--justify-space-around {
  justify-content: space-around;
}

.aw-2-flx--justify-end {
  justify-content: flex-end;
}

.aw-2-flx--justify-stretch {
  justify-content: stretch;
}

.aw-2-flx--column-reverse {
  flex-direction: column-reverse;
}

.aw-2-flx--wrap {
  flex-wrap: wrap;
}

.aw-2-min-w--0 {
  min-width: 0;
}

.aw-2-rotate--50y {
  transform: scaleY(-1);
  transform: rotate(180deg);
}

@each $number in (0, 1, 2, 3, 4) {
  .aw-2-flx--flex-#{$number} {
    flex: $number;
  }
  .aw-2-flx--shrink-#{$number} {
    flex-shrink: $number;
  }
  .aw-2-flx--grow-#{$number} {
    flex-grow: $number;
  }
}

@each $step, $size in $spacings {
  .aw-2-flx--gap-#{$step} {
    gap: $size;
  }
}
